import { defineStore } from "pinia";
import { Agreement, AgreementType } from "~/types/agreements";
import { Option, ResponseType } from "~/types/general";

export const useAgreementsStore = defineStore("agreements", () => {
  // State
  const agreements = ref<Agreement[]>([]);
  const viewingAgreementType = ref<AgreementType | null>(null);
  const agreementTypes = ref<{ name: string; value: AgreementType }[]>([
    { name: "Invoice", value: AgreementType.INVOICE },
    { name: "Preparation", value: AgreementType.PREPARATION },
    { name: "Confirmation", value: AgreementType.CONFIRMATION },
    { name: "Sales Summary", value: AgreementType.SALES_SUMMARY },
    { name: "Service Summary", value: AgreementType.SERVICE_SUMMARY },
  ]);

  // Getters
  const agreementsOptions = computed(() =>
    agreements.value.map(
      (agreement) => ({ name: agreement.name, value: agreement.id }) as Option
    )
  );

  // Actions
  // Get Agreements
  async function getAgreements(
    params?: any
  ): Promise<ResponseType<Agreement[]>> {
    try {
      const response = await useVaniloApi("/agreements", { params });
      return response as ResponseType<Agreement[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Get Single Agreement
  async function getSingleAgreement(
    id: number | string
  ): Promise<ResponseType<Agreement>> {
    try {
      const response = await useVaniloApi(`/agreements/${id}`);
      return response as ResponseType<Agreement>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Create Agreement
  async function createAgreement(body: {
    name: string;
    content: string;
    description?: string;
    reports?: AgreementType[];
  }): Promise<ResponseType<Agreement>> {
    try {
      const response = await useVaniloApi("/agreements", {
        method: "POST",
        body,
      });
      return response as ResponseType<Agreement>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Edit Agreement
  async function editAgreement(
    id: number | string,
    body: {
      name: string;
      content: string;
      description?: string;
      reports?: AgreementType[];
    }
  ): Promise<ResponseType<Agreement>> {
    try {
      const response = await useVaniloApi(`/agreements/${id}`, {
        method: "POST",
        body,
      });
      return response as ResponseType<Agreement>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Delete Agreement
  async function deleteAgreement(
    id: number | string
  ): Promise<ResponseType<Agreement>> {
    try {
      const response = await useVaniloApi(`/agreements/${id}`, {
        method: "DELETE",
      });
      return response as ResponseType<Agreement>;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    agreements,
    viewingAgreementType,
    agreementTypes,
    agreementsOptions,
    getAgreements,
    getSingleAgreement,
    createAgreement,
    editAgreement,
    deleteAgreement,
  };
});
